import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { IntroEventComponent } from './pages/intro-event/intro-event.component';
import { ChattingComponent } from './pages/chatting/chatting.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Globals } from './globals';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { DefaultDialogComponent } from './dialogs/default-dialog/default-dialog.component';
import { TermsDialogComponent } from './dialogs/terms-dialog/terms-dialog.component';
import { OnlyNumber } from './directives/only-number';
import { EventListComponent } from './pages/event-list/event-list.component';
import {MatBottomSheetModule, MAT_BOTTOM_SHEET_DEFAULT_OPTIONS} from '@angular/material/bottom-sheet';
import {MatListModule} from '@angular/material/list';
import { AnswerChooseComponent } from './component/answer-choose/answer-choose.component';
import { QuizDialogComponent } from './dialogs/quiz-dialog/quiz-dialog.component';
import { ScratchDialogComponent } from './dialogs/scratch-dialog/scratch-dialog.component';
import { OnlyDate, OnlyDate2 } from './common/pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AnswerChooserComponent } from './component/answer-chooser/answer-chooser.component';


@NgModule({
  declarations: [
    AppComponent,
    IntroEventComponent,
    DefaultDialogComponent,
    TermsDialogComponent,
    OnlyNumber,
    OnlyDate,
    OnlyDate2,
    ChattingComponent,
    EventListComponent,
    AnswerChooseComponent,
    QuizDialogComponent,
    ScratchDialogComponent,
    AnswerChooserComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgbModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    FroalaViewModule.forRoot(),
    FroalaEditorModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatBottomSheetModule,
    MatListModule,
    //   MatFormFieldModule,
  ],
  providers: [
    Globals, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, 
      useValue: {
        hasBackdrop: false
      }
    },
    NgbActiveModal,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DefaultDialogComponent,
    TermsDialogComponent,
    QuizDialogComponent,
    AnswerChooseComponent,
  ]
})
export class AppModule { }
