import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-answer-choose',
  templateUrl: './answer-choose.component.html',
  styleUrls: ['./answer-choose.component.scss']
})
export class AnswerChooseComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<AnswerChooseComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {names: string[]}) {}

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  ngOnInit(): void {
  }

}
