import { Injectable, Renderer2 } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError  } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { JwtTokenService } from './jwt-token.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DefaultDialogComponent } from '../dialogs/default-dialog/default-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(public global: Globals,
    private modalService: NgbModal,
    private jwt: JwtTokenService,
    protected router: Router,
    private spinner: NgxSpinnerService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show()
    // if(this.jwt.getJwtToken() != null){
    //   request = request.clone({
    //     setHeaders: {
    //       "x-auth-token": this.jwt.getJwtToken()
    //     }
    //   });
    // }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
            if(event.status == 200){

            }
        }
        return event;
    }),
    catchError((error: HttpErrorResponse) => {
        this.spinner.hide();
        if(error.status == 401){
          this.global.logOut()
          this.router.navigate(['/login'])
        }else{
          if (error.error.message) {
            const modalRef = this.modalService.open(DefaultDialogComponent);
            modalRef.componentInstance.title = '알림'
            modalRef.componentInstance.content = error.error.message
          }else{
            return throwError(error)
          }
        }
    }),
    finalize(() => {
      this.spinner.hide();
    }));
  }
}
