import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-quiz-dialog',
  templateUrl: './quiz-dialog.component.html',
  styleUrls: ['./quiz-dialog.component.scss']
})
export class QuizDialogComponent implements OnInit {
  @Input() title;
  @Input() content;
  @Input() positiveButton = "확인"
  @Input() negativeBUtton = "취소"
  @Input() showCancel = false
  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }
}
