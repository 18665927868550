import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScratchCard, SCRATCH_TYPE } from 'scratchcard-js'
import { Globals } from 'src/app/globals';

declare function setResultImage(imageName)
declare function sendKakaoLink(title, imageUrl, link)

@Component({
  selector: 'app-scratch-dialog',
  templateUrl: './scratch-dialog.component.html',
  styleUrls: ['./scratch-dialog.component.scss']
})
export class ScratchDialogComponent implements OnInit {
  @Input() positiveButton = "이벤트 참여 완료"
  @Input() negativeBUtton = "취소"
  @Input() resultText = ""
  @Input() showCancel = true
  @Input() isWin = false

  text
  constructor(public modal: NgbActiveModal, public global: Globals) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.loadScript(`assets/js/scratch.js`);
    setTimeout(() => {
      // this.text = this.resultText
      if(this.isWin){
        setResultImage('/assets/images/success.png')
      }else{
        setResultImage('/assets/images/fail.png')
      }
    }, 500);
  }

  public loadScript(url: string) {
    const scripts = document.getElementsByTagName('script')
    for (let i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes(url)) {
        const body = <HTMLDivElement>document.body;
        body.removeChild(scripts[i])
      }
    }

    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.type = 'text/javascript';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  kakao(){
    sendKakaoLink("[EV 판매왕 이벤트]", "https://api.kiadmc.com/api/v1/files/image/f7b2596437224ed781327137d63f0333", this.global.isDirect ? "https://www.kiaevcar.com/events1/ev" : "https://www.kiaevcar.com/events/ev")
    setTimeout(() => {
      this.modal.dismiss()  
    }, 100);
  }
}
