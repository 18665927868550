import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DefaultDialogComponent } from 'src/app/dialogs/default-dialog/default-dialog.component';
import { ScratchDialogComponent } from 'src/app/dialogs/scratch-dialog/scratch-dialog.component';
import { Globals } from 'src/app/globals';
import { ApiService } from 'src/app/services/api.service';
import { PagerService } from 'src/app/services/pager.service';
import { SeoService } from 'src/app/services/seo.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {
  currentPage: number = 1
  totalCount: number = 0
  pager: any = {}
  events = []
  endOfList: boolean = false
  isMobileSize = false
  pageSize = 9

  constructor(private api: ApiService,
    public global: Globals,
    @Inject(PLATFORM_ID) private platformId: Object,
    private pagerService: PagerService,
    private modal: NgbModal,
    private seo: SeoService,
    protected router: Router) { 
      global.isDirect = router.url.includes("events1")
    }

  ngOnInit() {
    this.setSeo()

    if (window.innerWidth < 768) {
      this.isMobileSize = true
      this.pageSize = 100
    }
    this.getList()
  }

  setSeo(){
    // this.seo.updateOgTitle("중섭불패")
    // this.seo.updateOgDescription("4세대 쏘렌토 인터렉티브 무협 웹툰 중섭불패")
    // this.seo.updateOgUrl(this.router.url)
    // this.seo.updateOgImage("https://www.kiaevcar.com/assets/images/kia_og_toon.jpg")
  }

  ngOnDestroy(){
    // this.seo.updateOgTitle("KIA DMC - 기아자동차 대리점 모바일 채널")
    // this.seo.updateOgDescription("KIA 관련 뉴스 및 자동차 정보와 즐거운 기아 웹툰을 만나실 수 있습니다.")
    // this.seo.updateOgUrl("https://www.kiaevcar.com")
    // this.seo.updateOgImage("https://www.kiaevcar.com/assets/images/meta_image.png")
  }

  refreshList() {
    this.events = []
    this.pager = {}
    this.currentPage = 1
    this.endOfList = false
    this.totalCount = 0
    this.getList()
  }

  getList() {
    this.api.getEvents(this.currentPage, this.pageSize).subscribe(response => {
      let now = moment().format('YY년 MM월 DD일')
      this.totalCount = response.pagination.total_cnt
      for (let data of response.data) {
        data.event_start_date = moment(data.event_start_date).format('YY년 MM월 DD일')
        data.event_end_date = moment(data.event_end_date).format('YY년 MM월 DD일')
        if (data.event_start_date <= now && data.event_end_date >= now) {
          data.status = '진행중'
        } else if (data.event_end_date < now) {
          data.status = '종료'
        } else {
          data.status = '진행예정'
        }
        // data.content = data.content.replace(/<(?:.|\n)*?>/gm, '')
        // data.event_start_date = moment(data.event_start_date).format('YY년 MM월 DD일')
        // data.event_end_date = moment(data.event_end_date).format('YY년 MM월 DD일')
      }

      //이벤트 시작이 빠른 날짜가 리스트의 제일 처음에 나오게
      let ing = _.sortBy(_.filter(response.data, event =>{
        return event.status == "진행중"
      }), function(event){ 
        return event.event_start_date
      })

      let yet = _.filter(response.data, event =>{
        return event.status == "진행예정"
      })
      let end = _.filter(response.data, event =>{
        return event.status == "종료"
      })

      this.events = []
      Array.prototype.push.apply(this.events, ing)
      Array.prototype.push.apply(this.events, yet)
      Array.prototype.push.apply(this.events, end)

      // if (this.pager.currentPage == undefined) {
      //   this.setPage(1, true)
      // }

      // let total = response.pagination.total_cnt
      // let size = response.pagination.size
      // if ((size * this.currentPage >= total) || this.isMobileSize) {
      //   this.endOfList = true
      // }
    })
  }

  setPage(page: number, isFirst: boolean = false) {
    if (page == 0 || page > this.pager.endPage || (this.currentPage == 1 && page == this.pager.startPage) || (this.currentPage == this.pager.endPage && page == this.pager.endPage)) {
      return
    }

    this.pager = this.pagerService.getPager(this.totalCount, page, this.pageSize);

    this.currentPage = page
    if (!isFirst) {
      this.getList()
    }
  }

  moreList() {
    if (!this.endOfList) {
      this.currentPage += 1
      this.getList()
    }
  }

  goEvent(event){
    if(event.status == "진행중"){
      if(this.global.isDirect){
        this.router.navigate([`/events1/ev/${event.idx}`])
      }else{
        this.router.navigate([`/events/ev/${event.idx}`])
      }
    }else{
      const modalRef = this.modal.open(DefaultDialogComponent);
      modalRef.componentInstance.title = '알림'
      if(event.status == "종료"){
        modalRef.componentInstance.content = "종료된 이벤트입니다."
      }else{
        modalRef.componentInstance.content = "준비 중인 이벤트입니다."
      }
    }
  }
}
