import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChattingComponent } from './pages/chatting/chatting.component';
import { EventListComponent } from './pages/event-list/event-list.component';
import { IntroEventComponent } from './pages/intro-event/intro-event.component';


const routes: Routes = [
  { path: '', redirectTo: 'events', pathMatch: 'full' },
  {
    path: 'events', children: [
      { path: '', redirectTo: 'ev', pathMatch: 'full'},
      {
        path: 'ev', children: [
          { path: '', component: EventListComponent },
          { path: ':id', children: [
            { path: '', component: IntroEventComponent },
            { path: 'chat', component: ChattingComponent },
          ]},
        ]
      }
    ]
  },
  {
    path: 'events1', children: [
      { path: '', redirectTo: 'ev', pathMatch: 'full'},
      {
        path: 'ev', children: [
          { path: '', component: EventListComponent },
          { path: ':id', children: [
            { path: '', component: IntroEventComponent },
            { path: 'chat', component: ChattingComponent },
          ]},
        ]
      }
    ]
  },
  { path: '**', redirectTo: 'list', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    onSameUrlNavigation: "reload",
    scrollPositionRestoration: "enabled",
    anchorScrolling: 'enabled',
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
