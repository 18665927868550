import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Message } from 'src/app/model/message';
import * as _ from 'underscore';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { AnswerChooseComponent } from 'src/app/component/answer-choose/answer-choose.component';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Globals } from 'src/app/globals';
import { ApiService } from 'src/app/services/api.service';
import { async } from 'rxjs/internal/scheduler/async';
import { DefaultDialogComponent } from 'src/app/dialogs/default-dialog/default-dialog.component';
import { QuizDialogComponent } from 'src/app/dialogs/quiz-dialog/quiz-dialog.component';
import { ScratchDialogComponent } from 'src/app/dialogs/scratch-dialog/scratch-dialog.component';
import { AnswerChooserComponent } from 'src/app/component/answer-chooser/answer-chooser.component';

@Component({
  selector: 'app-chatting',
  templateUrl: './chatting.component.html',
  styleUrls: ['./chatting.component.scss']
})
export class ChattingComponent implements OnInit {
  currentQuizOrder = 0
  quizzes = []
  answers = []//각 문제에 대해 선택 할 답변들
  messages = []//채팅화면에 뿌려질 내용
  endChats = []//마무리 채팅
  showAnswerChoose = false
  customerImg = ""
  customerName = ""
  botName = ""
  eventIdx: number
  quizIdx: number
  lastMessageSenderType = ""
  timer
  showDim = false

  constructor(private bottomSheet: MatBottomSheet,
    public router: Router,
    private api: ApiService,
    private formBuiler: FormBuilder,
    public deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private global: Globals) {
    this.eventIdx = route.snapshot.params['id']
    global.isDirect = router.url.includes("events1")
  }

  ngOnInit(){
    this.getQuizChatInfo()
    this.getQuizzes()
  }

  ngAfterViewInit(){
    this.timer = setInterval(()=>this.Timer(), 800);
  }

  Timer() {
    let buttons = document.getElementsByClassName("scrollPoint")
    let button = buttons[buttons.length - 1]
    if(button){
      button.scrollIntoView({behavior: 'smooth'})
    }
  }

  ngOnDestroy(){
    if(this.timer){
      clearInterval(this.timer)
    }
  }

  getQuizChatInfo(){
    this.api.getQuizChatInfo(this.eventIdx).subscribe(response => {
      this.customerImg = response.customer_image_url
      this.customerName = response.customer_name
      this.botName = response.chatbot_name

      this.endChats = response.end_chat_list
      this.defaultMent(response.start_chat_list)
    })
  }

  getQuizzes(){
    this.api.getEventQuizzes(this.eventIdx).subscribe(response => {
      this.quizzes = response.data
    })
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms*10) );
  }

  //기본 채팅(시작,끝)
  async defaultMent(messages, isLast = false){
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      let isFirst = false
      let isLast = false
      if(i == 0){
        isFirst = true
      }
      if(this.lastMessageSenderType != message.user_type){
        isFirst = true
      }
      if(i == messages.length - 1){
        isLast = true
      }else if(i < messages.length - 1){
        //다음 메시지가 현재 메시지와 다른 사람이라면, 현재 메시지의 버블은 마지막 모양
        let msg = messages[i+1]
        if(msg.user_type != message.user_type){
          isLast = true
        }
      }

      this.pushMessage(message, isFirst, isLast)
      
      await this.delay(message.end_delay + message.start_delay)
    }

    if(!isLast){
      this.nextQuiz()
    }else{
      let savedForm = localStorage.getItem("ev-event-info")
      let savedForm2 = localStorage.getItem("ev-event-login-info")
      if (savedForm) {
        this.checkPrize(savedForm)
      }else if (savedForm2) {
        this.checkPrize(savedForm2)
      }else{
        let _this = this
        const modalRef = this.modal.open(DefaultDialogComponent);
        modalRef.componentInstance.title = '알림'
        modalRef.componentInstance.content = "저장된 입력정보가 없습니다."
        modalRef.result.then(function (result) {
          if(_this.global.isDirect){
            _this.router.navigate([`/events1`])
          }else{
            _this.router.navigate([`/events`])
          }
        })
      }
    }
  }

  checkPrize(savedInfo){
    let eventInfo = JSON.parse(savedInfo, (key, value) => {
      return value
    })
    this.api.createEventJoin(this.eventIdx, {creator: eventInfo.code, name: eventInfo.name, phone: eventInfo.phone}).subscribe(response => {
      localStorage.removeItem('ev-event-info')

      const modalRef = this.modal.open(ScratchDialogComponent, { backdrop: 'static' });
      modalRef.componentInstance.isWin = response.isWin
      if(response.isWin){
        modalRef.componentInstance.showCancel = false
      }else{
        modalRef.componentInstance.positiveButton = "상담 재도전하기"
        modalRef.componentInstance.negativeBUtton = "처음으로 돌아가기"
      }
      let _this = this
      modalRef.result.then(function (result) {
        if(response.isWin){
          if(_this.global.isDirect){
            _this.router.navigate([`/events1`])
          }else{
            _this.router.navigate([`/events`])
          }
        }else{
          _this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            if(_this.global.isDirect){
              _this.router.navigate([`/events1/ev/${_this.eventIdx}/chat`])
            }else{
              _this.router.navigate([`/events/ev/${_this.eventIdx}/chat`])
            }
          })
        }
      }, function (reason) {
        if(_this.global.isDirect){
          _this.router.navigate([`/events1`])
        }else{
          _this.router.navigate([`/events`])
        }
      })
    })
  }

  async nextQuiz(){
    if(this.currentQuizOrder < this.quizzes.length){
      let quiz = this.quizzes[this.currentQuizOrder];
      this.quizIdx = quiz.idx
      let question = ""
      for (let i = 0; i < quiz.chat_list.length; i++) {
        const message = quiz.chat_list[i];
        if(message.user_type == "customer"){
          message.isQuestion = true
          question = message.content
        }
        let isFirst = false
        let isLast = false
        if(this.lastMessageSenderType != message.user_type){
          isFirst = true

          //마지막 메시지의 버블 모양을 변경
          let lastMessage = this.messages[this.messages.length - 1]
          lastMessage.isLastOfGroup = true
        }
  
        this.pushMessage(message, isFirst, isLast)
        await this.delay(message.end_delay + message.start_delay)
      }
      this.answers = quiz.event_quiz_answers
      this.showAnswerChoose = true
      
      // this.showDim = true
      // const bottomSheetRef = this.bottomSheet.open(AnswerChooserComponent, {data: {question: question, answers: this.answers} })
      // bottomSheetRef.afterDismissed().subscribe(result => {
      //   console.log(result)
      //   this.showDim = false
      //   this.chooseAnswer(result.content, result.idx)
      // });
    }else{
      this.defaultMent(this.endChats, true)
    }
  }

  async pushMessage(message, isFirst, isLast, showAnswer = false){
    this.lastMessageSenderType = message.user_type == "customer" ? "customer" : "chatbot"
    let wait = {
      isMe: message.user_type == "customer" ? false : true,
      isFirstOfGroup: isFirst,
      isLastOfGroup: isLast,
      name: message.user_type == "customer" ? this.customerName : this.botName,
      content: "..."
    }
    this.messages.push(wait)
    await this.delay(message.start_delay)

    let msg = {
      isMe: message.user_type == "customer" ? false : true,
      isFirstOfGroup: isFirst,
      isLastOfGroup: isLast,
      name: message.user_type == "customer" ? this.customerName : this.botName,
      content: message.content,
      question: message.isQuestion
    }

    this.messages.splice(-1,1)
    this.messages.push(msg)
    this.showAnswerChoose = showAnswer
  }

  chooseAnswer(description, answerIdx){
    this.api.checkEventQuizAnwer(this.eventIdx, this.quizIdx, answerIdx).subscribe(response => {
      if(response.right){
        this.lastMessageSenderType = "chatbot"
        let lastMessage = this.messages[this.messages.length - 1]
        let isFirst = true
        if(lastMessage.isMe){
          isFirst = false
        }
        this.messages.push({
          isMe: true,
          isFirstOfGroup: isFirst,
          isLastOfGroup: false,
          name: this.botName,
          content: description
        })
        this.showAnswerChoose = false
        this.currentQuizOrder++
    
        this.nextQuiz()
      }else{
        let _this = this
        const modalRef = this.modal.open(QuizDialogComponent, {backdrop: 'static'});
        modalRef.componentInstance.showCancel = true
        modalRef.componentInstance.title = "알림"
        modalRef.componentInstance.content = "고객의 마음을 얻는데 실패!!"
        modalRef.componentInstance.positiveButton = "상담 재도전하기"
        modalRef.componentInstance.negativeBUtton = "처음으로 돌아가기"
        modalRef.result.then(function (result) {
          _this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            if(_this.global.isDirect){
              _this.router.navigate([`/events1/ev/${_this.eventIdx}/chat`])
            }else{
              _this.router.navigate([`/events/ev/${_this.eventIdx}/chat`])
            }
          })
        }, function (reason) {
          if(_this.global.isDirect){
            _this.router.navigate([`/events1`])
          }else{
            _this.router.navigate([`/events`])
          }
        })
      }
    })
  }
}
