import { Injectable } from '@angular/core';
import * as JWT from 'jwt-decode'
import { Environment } from './Environment';
import { User } from './model/user';

@Injectable({
    providedIn: 'root'
  })
export class Globals {
    public BACKEND_HOST:string = Environment.BACKEND_HOST
    isLogOn: boolean = false
    isMobile: boolean = false
    isDirect: boolean = false//기아 지점인지
    user: User = new User()
    froalaKey : string = "cB10C9C5D5C-11A2B2C2H2C9B3C4C6D2A1wCHCPa1XQVZFSHSa1C=="

    logIn(token){
        localStorage.setItem('jwt_token', token);
        this.isLogOn = true
    }

    logOut() {
        localStorage.removeItem("jwt_token");
        this.isLogOn = false
        this.removeAotuLotin()
    }

    setUser(user){
        this.user.id = user.id
        this.user.auth = user.auth
        this.user.email = user.email
        this.user.name = user.name
    }

    setAutoLogin(id){
        localStorage.setItem('auto_login', id);
    }

    removeAotuLotin(){
        localStorage.removeItem("auto_login");
    }

    getAutoLogin(): string{
        return localStorage.getItem('auto_login')
    }

    public isLoggedIn(): boolean {
        var result: boolean = false
        var token: string = localStorage.getItem('jwt_token')
        
        if(token != null && token != undefined && token.length > 0){
            let userData: any = JWT(token);
            
            this.user.id = userData.id
            this.user.name = userData.name
            this.user.auth = userData.scopes[0]
            
            this.isLogOn = true
            result = true
        }else{
            this.isLogOn = false
        }
        return result
    }
}