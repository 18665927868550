import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-default-dialog',
  templateUrl: './default-dialog.component.html',
  styleUrls: ['./default-dialog.component.scss']
})
export class DefaultDialogComponent implements OnInit {
  @Input() title;
  @Input() content;
  @Input() positiveButton = "확인"
  @Input() negativeBUtton = "취소"
  @Input() showCancel = false
  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }
}
