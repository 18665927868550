import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl

  constructor(
      private http: HttpClient,
    public global: Globals) {
    this.apiUrl = global.BACKEND_HOST + '/api/v1'
  }

  login(id: string, registration_id: string){
    let vo = { id: id, registration_id: registration_id}
    if(registration_id == undefined || registration_id.length == 0){
      delete vo["registration_id"]
    }
    return this.http.post<any>(`${this.apiUrl}/auth`, vo, { headers: httpOptions.headers });
  }

  getFixedBoards(){
    return this.http.get<any>(`${this.apiUrl}/fix-boards`, { headers: httpOptions.headers })
  }

  getRecentlyBoards(size:number=10){
    let params = new HttpParams().set("size", size +"")
    params = params.append("board_types", "normal,image,crm,image_sub")
    return this.http.get<any>(`${this.apiUrl}/additional-data/board/recently`, { headers: httpOptions.headers, params: params })
  }

  getMainBanner(size: number = 10){
    let params = new HttpParams().set("page", "1")
    params = params.append("size", `${size}`)
    params = params.append("visible", "true")
    return this.http.get<any>(`${this.apiUrl}/main-banner`, { headers: httpOptions.headers, params: params })
  }

  getSubBanner(size: number = 10){
    let params = new HttpParams().set("page", "1")
    params = params.append("size", `${size}`)
    params = params.append("visible", "true")
    return this.http.get<any>(`${this.apiUrl}/sub-banner`, { headers: httpOptions.headers, params: params })
  }

  getCertificateInfo(uuid: string){
    return this.http.get<any>(`${this.apiUrl}/certificate/${uuid}`, { headers: httpOptions.headers })
  }

  getCrmBoards(category: string, page: number, size: number = 5, search_keyword: string = "", search_type: string = "title"){
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", `${size}`)
    params = params.append("board_category_name", category)
    if(search_keyword.length > 0){
      params = params.append("search_keyword", search_keyword)
      params = params.append("search_type", search_type)
    }
    
    return this.http.get<any>(`${this.apiUrl}/crm-boards`, { headers: httpOptions.headers, params: params })
  }

  getCrmBoardDetail(category: string, idx: number){
    let params = new HttpParams().set("board_category_name", `${category}`)
    return this.http.get<any>(`${this.apiUrl}/crm-boards/${idx}`, { headers: httpOptions.headers, params: params })
  }

  createNormalBoard(boardVO: { board_category_name: string, title: string, content: string, password: string }) {
    return this.http.post<any>(`${this.apiUrl}/normal-boards`, boardVO, { headers: httpOptions.headers })
  }

  getNormalBoards(category: string, page: number, size: number = 5, search_keyword: string = "", search_type: string = "title"){
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", `${size}`)
    params = params.append("board_category_name", category)
    if(search_keyword.length > 0){
      params = params.append("search_keyword", search_keyword)
      params = params.append("search_type", search_type)
    }

    return this.http.get<any>(`${this.apiUrl}/normal-boards`, { headers: httpOptions.headers, params: params })
  }

  getNormalBoardDetail(category: string, idx: number, password: string = undefined){
    let params = new HttpParams().set("board_category_name", `${category}`)
    if (password) {
      params = params.append("password", password)
    }
    return this.http.get<any>(`${this.apiUrl}/normal-boards/${idx}`, { headers: httpOptions.headers, params: params })
  }

  modifyNormalBoard(idx:number, modifyBoardVO: any) {
    return this.http.put<any>(`${this.apiUrl}/normal-boards/${idx}`, modifyBoardVO, { headers: httpOptions.headers })
  }
  delNormalBoard(idx:number) {
    return this.http.delete<any>(`${this.apiUrl}/normal-boards/${idx}`, { headers: httpOptions.headers })
  }

  getCars(page: number, size: number = 3, search_keyword: string = "", search_type: string = "title", carType: string = "차량 정보",sorting:string=undefined){
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", `${size}`)
    params = params.append("board_category_name", carType)
    if(search_keyword.length > 0){
      params = params.append("search_keyword", search_keyword)
      params = params.append("search_type", search_type)
    }
    if(sorting!=undefined){
      params = params.append("sorting", sorting)
    }
    
    return this.http.get<any>(`${this.apiUrl}/image-boards`, { headers: httpOptions.headers, params: params })
  }

  getCarDetail(idx, subIdx){
    return this.http.get<any>(`${this.apiUrl}/image-boards/${idx}/sub-boards/${subIdx}`, { headers: httpOptions.headers })
  }

  getCarSub(idx: number, page: number, size: number = 3, carType: string = "차량 정보"){
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", `${size}`)
    params = params.append("board_category_name", carType)
    return this.http.get<any>(`${this.apiUrl}/image-boards/${idx}/sub-boards`, { headers: httpOptions.headers, params: params })
  }
  
  getNeighborhood(size: number, board_type: string, targetIdx: number, type: string){
    let params = new HttpParams().set("size", `${size}`)
    params = params.append("board_type", `${board_type}`)
    params = params.append("target_idx", `${targetIdx}`)
    if(type != undefined){
      params = params.append("category", `${type}`)
    }

    return this.http.get<any>(`${this.apiUrl}/additional-data/board/neighborhood`, { headers: httpOptions.headers, params: params })
  }

  search(q: string, page: number, size: number = 3){
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", `${size}`)
    params = params.append("q", `${q}`)
    return this.http.get<any>(`${this.apiUrl}/search`, { headers: httpOptions.headers, params: params })
  }

  setPushStatus(id: string, value: boolean){
    return this.http.put<any>(`${this.apiUrl}/users/${id}/push`, {push: value}, { headers: httpOptions.headers });
  }

  getPushStatus(id: string){
    return this.http.get<any>(`${this.apiUrl}/users/${id}/push`, { headers: httpOptions.headers });
  }

  existCheck(id: string, checkType = undefined){
    if(checkType){
      let params = new HttpParams().set("user_type", `${checkType}`)
      return this.http.get<any>(`${this.apiUrl}/users/${id}`, { headers: httpOptions.headers, params: params });  
    }else{
      return this.http.get<any>(`${this.apiUrl}/users/${id}`, { headers: httpOptions.headers });
    }
  }

  getTerms(idx){
    return this.http.get<any>(`${this.apiUrl}/terms/${idx}`, { headers: httpOptions.headers });
  }
  
  getEvents(page: number, size: number) {
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", `${size}`)
    params = params.append("event_type", `chat`)
    return this.http.get<any>(`${this.apiUrl}/events`, { headers: httpOptions.headers, params: params });
  }

  getEventDetail(idx: number) {
    return this.http.get<any>(`${this.apiUrl}/events/${idx}`, { headers: httpOptions.headers });
  }

  createEventJoin(idx: number, vo: any) {
    return this.http.post<any>(`${this.apiUrl}/events/${idx}/users`, vo, { headers: httpOptions.headers });
  }

  getQuizChatInfo(idx){
    return this.http.get<any>(`${this.apiUrl}/events/${idx}/chat`, { headers: httpOptions.headers });
  }

  getEventQuizzes(idx, page: number = undefined, size: number = undefined) {
    if (page && size) {
      let params = new HttpParams().set("page", `${page}`)
      params = params.append("size", `${size}`)
      return this.http.get<any>(`${this.apiUrl}/events/${idx}/quizzes`, { headers: httpOptions.headers, params: params });
    } else {
      return this.http.get<any>(`${this.apiUrl}/events/${idx}/quizzes`, { headers: httpOptions.headers });
    }
  }

  getEventWinBoards(idx, page: number = undefined, size: number = undefined) {
    if (page && size) {
      let params = new HttpParams().set("page", `${page}`)
      params = params.append("size", `${size}`)
      return this.http.get<any>(`${this.apiUrl}/events/${idx}/win-boards`, { headers: httpOptions.headers, params: params });
    } else {
      return this.http.get<any>(`${this.apiUrl}/events/${idx}/win-boards`, { headers: httpOptions.headers });
    }
  }

  checkEventQuizAnwer(event_idx: number, quiz_idx: number, answer_idx: number) {
    return this.http.post<any>(`${this.apiUrl}/events/${event_idx}/quizzes/${quiz_idx}/answers`, { answer_idx: answer_idx }, { headers: httpOptions.headers });
  }
}
