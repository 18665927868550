import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DefaultDialogComponent } from 'src/app/dialogs/default-dialog/default-dialog.component';
import { ScratchDialogComponent } from 'src/app/dialogs/scratch-dialog/scratch-dialog.component';
import { TermsDialogComponent } from 'src/app/dialogs/terms-dialog/terms-dialog.component';
import { Globals } from 'src/app/globals';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-intro-event',
  templateUrl: './intro-event.component.html',
  styleUrls: ['./intro-event.component.scss']
})
export class IntroEventComponent implements OnInit {
  form: FormGroup = this.formBuiler.group({
    code: ['', Validators.required],
    name: ['', Validators.required],
    phone: ['', Validators.required],
    save: true,
    agree: [true, Validators.requiredTrue]
  })
  returnUrl: string
  pushToken: string
  eventIdx: number

  termsTitle
  termsContent

  constructor(
    private router: Router,
    private api: ApiService,
    private formBuiler: FormBuilder,
    public deviceService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private global: Globals
  ) {
    this.eventIdx = route.snapshot.params['id']
    global.isDirect = router.url.includes("events1")
  }

  ngOnInit() {
    let savedForm = localStorage.getItem("ev-event-login-info")
    if (savedForm) {
      let eventInfo = JSON.parse(savedForm, (key, value) => {
        return value
      })
      this.form.patchValue({code: eventInfo.code})
      this.form.patchValue({name: eventInfo.name})
      this.form.patchValue({phone: eventInfo.phone})
    }

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    this.api.getEventDetail(this.eventIdx).subscribe(response => {
      this.termsTitle = "개인정보 취급방침"
      this.termsContent = response.policy_content
    })
  }

  terms() {
    const modalRef = this.modal.open(TermsDialogComponent);
    modalRef.componentInstance.title = this.termsTitle
    modalRef.componentInstance.content = this.termsContent
    modalRef.result.then(function (result) {
      console.log(result)
    }, function (reason) {
      console.log(reason)
    })
  }

  startEngine() {
    if (this.form.valid) {
      var regexp = new RegExp('010[0-9]{8}')
      if(!regexp.test(this.form.value.phone)){
        const modalRef = this.modal.open(DefaultDialogComponent);
        modalRef.componentInstance.title = '알림'
        modalRef.componentInstance.content = "연락처를 확인해주세요."
        return
      }
      let user_type = "sub"
      let failMent = "대리점 코드를 확인해주세요."
      if(this.global.isDirect){
        user_type = "direct"
        failMent = "직영점 코드를 확인해주세요."
      }
      this.api.existCheck(this.form.value.code, user_type).subscribe(response => {
        if(response.exist){
          if(this.form.value.save){
            let info = {code: this.form.value.code, name: this.form.value.name, phone: this.form.value.phone}
            localStorage.setItem('ev-event-login-info', JSON.stringify(info))  
          }else{
            localStorage.removeItem('ev-event-login-info')
          }
          localStorage.setItem('ev-event-info', JSON.stringify(this.form.getRawValue()))
          if(this.global.isDirect){
            this.router.navigate([`/events1/ev/${this.eventIdx}/chat`])
          }else{
            this.router.navigate([`/events/ev/${this.eventIdx}/chat`])
          }
        }else{
          const modalRef = this.modal.open(DefaultDialogComponent);
          modalRef.componentInstance.title = '알림'
          modalRef.componentInstance.content = failMent
        }
      })
    }else{
      const modalRef = this.modal.open(DefaultDialogComponent);
      modalRef.componentInstance.title = '알림'
      if(!this.form.value.agree == true){
        modalRef.componentInstance.content = "개인정보 제공에 동의해주세요."
      }else{
        modalRef.componentInstance.content = "코드, 이름, 연락처를 입력해주세요."
      }
    }
  }
}
