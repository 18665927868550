import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';


@Component({
  selector: 'app-answer-chooser',
  templateUrl: './answer-chooser.component.html',
  styleUrls: ['./answer-chooser.component.scss']
})
export class AnswerChooserComponent implements OnInit {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<AnswerChooserComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit(): void {
  }

  onSelect(answer): void {
    this._bottomSheetRef.dismiss(answer);
  }
}
