import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }
  
  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  updateOgTitle(title: string) {
    if(title && title.length > 0){
      this.meta.updateTag({ property: 'og:title', content: title })
    }else{
      this.meta.updateTag({ property: 'og:title', content: "KIA DMC - 기아자동차 대리점 모바일 채널" })
    }
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: "https://www.kiaevcar.com" + url })
  }

  updateOgImage(image: string) {
    if(image){
      this.meta.updateTag({ property: 'og:image', content: image })
    }else{
      this.meta.updateTag({ property: 'og:image', content: "https://www.kiaevcar.com/assets/images/meta_image.png" })
    }
  }

  updateOgDescription(description: string) {
    this.meta.updateTag({ property: 'og:description', content: description })
  }
}
