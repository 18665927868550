import { Pipe, PipeTransform } from "@angular/core";
import * as moment from 'moment';
@Pipe({
    name: 'numberToKorean'
})
export class NumberToKorean implements PipeTransform {
    transform(val): string {
        if (val == undefined || isNaN(val)) {
            return ""
        }
        val = val + ""
        let num = val.substr(1)
        let sub = true
        if (Number(num)) {
            sub = false
        }
        var hanA = new Array("", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구", "십");
        var danA = new Array("", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천");
        var result = "";
        for (let i = 0; i < val.length; i++) {
            let str = "";
            let han = hanA[val.charAt(val.length - (i + 1))];
            if (han != "") str += han + danA[i];
            if (i == 4) {
                str += "만";
            }
            if (i == 8) {
                str += "억";
            }
            // if(i == 12){
            //     str += "조";
            // } 

            result = str + result;
        }

        if (result.includes("억") && sub) {
            result = result.replace("만", "")
        }

        if (val != "0") result = result + "원";

        return result
    }
}

@Pipe({
    name: 'numberToKorean2'
})
export class NumberToKorean2 implements PipeTransform {
    transform(val): string {
        if (val == undefined || isNaN(val)) {
            return ""
        }
        val = val + ""
        let num = val.substr(1)
        let sub = true
        if (Number(num)) {
            sub = false
        }
        var hanA = new Array("", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구", "십");
        var danA = new Array("", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천");
        var result = "";
        for (let i = 0; i < val.length; i++) {
            let str = "";
            let han = hanA[val.charAt(val.length - (i + 1))];
            if (han != "") str += han + danA[i];
            if (i == 4) {
                str += "억";
            }

            result = str + result;
        }

        // console.log(result)

        if (val != "0") result = result + "만원"

        if (result.includes("억")) {
            result = result.replace("만", "")
        }
        if (result.lastIndexOf("천") > 1) {
            result = result.replace("원", "만원")
        }

        return result
    }
}

@Pipe({
    name: 'onlyDate'
})
export class OnlyDate implements PipeTransform {
    transform(fullDate: string): string {
        let result = ''
        if (fullDate.length == 19) {
            result = fullDate.substring(0, 10)
        }

        return result
    }
}

@Pipe({
    name: 'onlyDate2'
})
export class OnlyDate2 implements PipeTransform {
    transform(fullDate: string): string {
        const result = moment(fullDate).format('YYYY.MM.DD')
        return result
    }
}

@Pipe({
    name: 'formatTime'
})
export class FormatTime implements PipeTransform {
    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return (
            ("00" + minutes).slice(-2) +
            ":" +
            ("00" + Math.floor(value - minutes * 60)).slice(-2)
        );
    }
}

