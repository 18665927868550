import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenService {

  constructor() { }

  public getJwtToken(): string {
    return localStorage.getItem('jwt_token');
  }

  public setJwtToken(token){
    localStorage.setItem('jwt_token', token)
  }

  public removeJwtToken(){
    localStorage.removeItem('jwt_token')
  }
}
